import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { CommonService } from 'app/service/common.service';
import moment from 'moment';
import { DatePickerDirective } from 'ng2-date-picker';
import { Subscription } from 'rxjs';
import _ from 'lodash';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { TicketEditorService } from 'app/service/ticket-editor.service';
import { DialogService } from 'app/service/dialog.service';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { SaleSummaryOrder } from 'app/model/entity/ticket-manager/saleSummaryOrder';
import { ParticipantCount } from 'app/model/entity/ticket-manager/paticipantCount';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { MenuActionService } from 'app/service/menu-action.service';

@Component({
  selector: 'tab-sale-result',
  templateUrl: './tab-sale-result.component.html',
  styleUrls: ['./tab-sale-result.component.scss']
})
export class TabSaleResultComponent implements OnInit {
  @Input() tabSelected: number; // tab selected
  @Input() listApp: ApplicationDTO[] = []; // list Application
  @Input() informationAccount: any;
  subscriptions: Array<Subscription> = new Array<Subscription>(); //array subscription
  languageKey: string;
  languageSelected: string;
  config: any;
  dataSearch = { saleTicketId: '', startDate: undefined, endDate: undefined };
  orderOrigin: SaleSummaryOrder;
  listOrdersDisplay1: ParticipantCount[] = [];
  listOrdersDisplay2: ParticipantCount[] = [];
  appIdSelected: any;
  listSaleTicket: any[] = [];
  Helper = Helper;
  constructor(
    private translateService: TranslateService,
    private commonService: CommonService,
    private ticketService: TicketEditorService,
    private dialogService: DialogService,
    private menuActionService: MenuActionService
  ) {
    this.subscriptions.push(
      // Clear setting
      this.menuActionService.actionClearSetting.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketManagerComponent] && this.tabSelected == Constant.SALE_RESULT_ENUM) {
          this.clearSearch();
        }
      }),
      this.translateService.onLangChange.subscribe(() => {
        // multi language
        this.languageKey = this.commonService.getCommonObject().setting?.language;
        this.languageSelected = this.languageKey == 'en' ? 'en' : 'ja';
        this.config = {
          showWeekNumbers: false,
          format: 'YYYY-MM-DD',
          firstDayOfWeek: 'mo',
          unSelectOnClick: false,
          locale: Helper.getLocale(this.languageKey),
          min: '2023-01-01'
        };
      })
    );
  }

  ngOnInit(): void {
    this.languageKey = this.commonService.getCommonObject().setting?.language;
    this.languageSelected = this.languageKey == 'en' ? 'en' : 'ja';
    this.config = {
      showWeekNumbers: false,
      format: 'YYYY-MM-DD',
      firstDayOfWeek: 'mo',
      unSelectOnClick: false,
      locale: Helper.getLocale(this.languageKey),
      min: '2023-01-01'
    };
  }

  /**
   * changeApp
   * @param appId
   * @returns
   */
  changeApp(appId: string): void {
    this.dataSearch.saleTicketId = null;
    this.listSaleTicket = [];
    if (!appId) {
      return;
    }
    this.ticketService.getSalesTickets(this.informationAccount, null, this.appIdSelected).subscribe(data => {
      if (!data || !data.length) {
        return;
      }
      this.listSaleTicket = data;
    });
  }

  /**
   * open date picker
   * @param picker
   * @param time
   */
  openDatePicker(picker: DatePickerDirective, time: any): void {
    picker.api.open();
    this.addPseudoSpan();
    picker.api.moveCalendarTo(time ?? moment());
  }

  /**
   * add element date picker
   */
  addPseudoSpan(): void {
    while (document.getElementById('span-new')) {
      document.getElementById('span-new').remove();
    }
    return;
  }

  /**
   * search
   * @returns
   */
  search(): void {
    this.orderOrigin = null;
    this.listOrdersDisplay1 = [];
    this.listOrdersDisplay2 = [];
    if (!this.validateDate() || !this.appIdSelected || !this.dataSearch.saleTicketId) {
      return;
    }
    this.ticketService.getSaleSummaryOder(this.informationAccount, this.dataSearch).subscribe(data => {
      if (!data) {
        return;
      }
      this.orderOrigin = data;
      let participantCountByDate = this.orderOrigin.participantCountByDate;
      if (!participantCountByDate || !participantCountByDate.length) {
        return;
      }
      if (participantCountByDate.length > 19) {
        this.listOrdersDisplay1 = participantCountByDate.slice(0, 19);
        this.listOrdersDisplay2 = participantCountByDate.slice(19, participantCountByDate.length);
      } else {
        this.listOrdersDisplay1 = participantCountByDate;
      }
    });
  }

  /**
   * validateDate
   * @returns
   */
  validateDate(): boolean {
    const startDate = this.dataSearch.startDate;
    const endDate = this.dataSearch.endDate;
    const currentDate = new Date();
    var year = currentDate.getFullYear();
    var month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
    var day = ('0' + currentDate.getDate()).slice(-2);
    var formattedDate = year + '-' + month + '-' + day;
    if (startDate > formattedDate) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-manager.tab-report.validate-startDate-error')
        }
      });
      return;
    } else if (endDate < startDate) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-manager.tab-report.validate-endDate-error')
        }
      });
      return;
    } else if (endDate > formattedDate) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-manager.tab-report.validate-endDate-error1')
        }
      });
      return;
    } else if (moment(endDate).diff(moment(startDate), 'days') < 0 || moment(endDate).diff(moment(startDate), 'days') >= 31) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-manager.tab-report.validate-range')
        }
      });
      return;
    }
    return true;
  }

  /**
   * getTitle
   * @returns
   */
  public getTitleApp(): string {
    return this.listApp?.find(app => app.appId == this.appIdSelected)?.appName;
  }

  /**
   * getTitleTicketSale
   * @returns
   */
  getTitleTicketSale(): string {
    return this.listSaleTicket?.find(ticket => ticket.saleTicketId == this.dataSearch['saleTicketId'])?.ticketName[this.languageSelected];
  }

  /**
   * changeDisplayTicket
   * @param str
   * @returns
   */
  changeDisplay(str: string): string {
    if (!str) {
      return;
    }
    let result = 0;
    for (let i = 0; i < str.length; i++) {
      if (/[^\x00-\xff]|[mMwW]/.test(str.charAt(i))) {
        result += 2;
      } else {
        result += 1;
      }
      if (result >= 36) {
        return str.substring(0, i) + '...';
      }
    }
    return str;
  }

  /**
   * getSum
   * @param item
   */
  getSum(item: any, prop: string): number {
    if (!item || !prop) {
      return 0;
    }
    let sum = 0;
    if (!Helper.isEmpty(item[`${prop}Adults`])) {
      sum += item[`${prop}Adults`];
    }
    if (!Helper.isEmpty(item[`${prop}Custom1`])) {
      sum += item[`${prop}Custom1`];
    }
    if (!Helper.isEmpty(item[`${prop}Custom2`])) {
      sum += item[`${prop}Custom2`];
    }
    if (!Helper.isEmpty(item[`${prop}Custom3`])) {
      sum += item[`${prop}Custom3`];
    }
    return sum;
  }

  /**
   * getTotalRemain
   */
  getTotalRemain(): number {
    if (
      !this.orderOrigin ||
      Helper.isEmpty(this.orderOrigin.maxParticipantCount) ||
      Helper.isEmpty(this.orderOrigin.currentParticipantCount)
    ) {
      return;
    }
    return this.orderOrigin.maxParticipantCount - this.orderOrigin.currentParticipantCount;
  }

  /**
   * isDisabledSearch
   * @returns
   */
  isDisabledSearch() {
    if (!this.dataSearch.startDate || !this.dataSearch.endDate || !this.appIdSelected || !this.dataSearch.saleTicketId) {
      return true;
    }
    return false;
  }

  /**
   * clearSearch
   */
  clearSearch() {
    this.appIdSelected = null;
    this.dataSearch.startDate = undefined;
    this.dataSearch.endDate = undefined;
    this.dataSearch.saleTicketId = undefined;
    this.listSaleTicket = [];
    this.orderOrigin = null;
    this.listOrdersDisplay1 = [];
    this.listOrdersDisplay2 = [];
  }

  /**
   * getDayByDate
   * @param date
   */
  getDayByDate(date: any): string {
    if (!date) {
      return;
    }
    const day = new Date(date).getDay();
    const daysJa = ['日', '月', '火', '水', '木', '金', '土'];
    const daysEn = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    if (this.languageKey == 'en') {
      return daysEn[day];
    } else {
      return daysJa[day];
    }
  }

  /**
   * getTotal
   * @param input
   * @returns
   */
  getTotal(input: string): number {
    if (!input) {
      return;
    }
    let sum = 0;
    if (this.listOrdersDisplay1 && this.listOrdersDisplay1.length) {
      this.listOrdersDisplay1.forEach(e => {
        sum += this.getSum(e, input);
      });
    }
    if (this.listOrdersDisplay2 && this.listOrdersDisplay2.length) {
      this.listOrdersDisplay2.forEach(e => {
        sum += this.getSum(e, input);
      });
    }
    return sum;
  }
}
